import { graphql, useStaticQuery } from "gatsby";

const query = () => {
  return useStaticQuery(graphql`
    query {
      allContentfulCareers {
        nodes {
          node_locale
          title
          descriptionRight {
            descriptionRight
          }
          titleImage {
            gatsbyImageData
            title
            file {
              url
            }
          }
          imageRight {
            gatsbyImageData
            title
            file {
              url
            }
          }
        }
      }
      allContentfulCareersItem {
        nodes {
          node_locale
          id
          itemName
          itemDescription {
            itemDescription
          }
          imageRight {
            gatsbyImageData
            title
            file {
              url
            }
          }
        }
      }
    }
  `);
};

export default query;
