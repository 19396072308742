import { graphql, useStaticQuery } from "gatsby";

const query = () => {
  return useStaticQuery(graphql`
    query {
      allContentfulWorkWithUs {
        nodes {
          node_locale
          title
          textLeft {
            textLeft
          }
          textRight {
            textRight
          }
          link
          linkName
        }
      }
      allContentfulWorkWithUsItem(sort: { fields: order }) {
        nodes {
          node_locale
          id
          title1
          title2
          order
          text1 {
            raw
          }
          text2 {
            raw
          }
          applyForAJobText {
            raw
          }
        }
      }
    }
  `);
};

export default query;
