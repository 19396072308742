import * as React from "react";
import "./Item.scss";
import { Col } from "react-bootstrap";
import PropTypes from "prop-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

const Item = (props) => {
  function displayBox(id) {
    const id_box = "#id-box-" + id;
    const id_arrow = "#id-arrow-" + id;
    document.querySelector(id_box).classList.toggle("show");
    if (document.querySelector(id_arrow).classList.contains("px-arrow-down")) {
      document
        .querySelector(id_arrow)
        .classList.replace("px-arrow-down", "px-arrow-right");
    } else {
      document
        .querySelector(id_arrow)
        .classList.replace("px-arrow-right", "px-arrow-down");
    }
  }

  function rendererWorkWithUs(el, length) {
    if (length === 1) {
      return (
        <div key={el.id} className="work-with-us-item">
          <div className="work-with-us-item-row show">
            <Col className="col-md-12 col-12 d-inline-block work-with-us-right">
              <div className="work-with-us-item-title2 careers-text">
                {el.title1}
              </div>
            </Col>
          </div>
        </div>
      );
    } else if (el.order !== 0) {
      return (
        <div key={el.id} className="work-with-us-item">
          <div
            className="work-with-us-item-row"
            onClick={() => displayBox(el.id)}
          >
            <Col className="col-md-6 col-11 d-inline-block work-with-us-left">
              <div className="work-with-us-item-title1 careers-title">
                {el.title1}
              </div>
            </Col>
            <Col className="col-md-5 col-11 d-inline-block work-with-us-right">
              <div className="work-with-us-item-title2 careers-text">
                {el.title2}
              </div>
            </Col>
            <Col
              id={"id-arrow-" + el.id}
              className="col-1 d-inline-block px-arrow-right careers-title work-with-us-arrow"
            />
          </div>
          <div
            id={"id-box-" + el.id}
            className="work-with-us-item-description-box"
          >
            <Col className="col-md-6 col-12 d-inline-block" />
            <Col className="col-md-6 col-12 d-inline-block work-with-us-item-description">
              <div className="work-with-us-item-description-section careers-text">
                {documentToReactComponents(JSON.parse(el.text1.raw))}
              </div>
              <div className="work-with-us-item-description-section careers-text">
                {documentToReactComponents(JSON.parse(el.text2.raw))}
              </div>
              <div className="work-with-us-item-description-section careers-text">
                {documentToReactComponents(JSON.parse(el.applyForAJobText.raw))}
              </div>
            </Col>
          </div>
        </div>
      );
    }
  }

  return (
    <div className="work-with-us-items">
      {props.workWithUsItemsData.map((el) =>
        rendererWorkWithUs(el, props.workWithUsItemsData.length)
      )}
    </div>
  );
};

Item.propTypes = {
  workWithUsItemsData: PropTypes.object.isRequired,
};

export default Item;
