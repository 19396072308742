import * as React from "react";
import { Col, Container } from "react-bootstrap";
import "./Careers.scss";
import query from "./query";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import Item from "./Item/Item";
import {
  filterDataByLanguage,
  filterListDataByLanguage,
} from "../../Utils/Language";
import PropTypes from "prop-types";

const Careers = (props) => {
  const data = query();
  const careersData = filterDataByLanguage(
    props.lang,
    data.allContentfulCareers
  );
  const careersItemsData = filterListDataByLanguage(
    props.lang,
    data.allContentfulCareersItem
  );

  return (
    <div className="careers-content">
      <div className="careers-intro">
        <Container>
          <Col className="col-md-9 col-12 careers-intro-title careers-title">
            {careersData.title}
          </Col>
          <Col className="col-md-9 col-12 careers-intro-image">
            <GatsbyImage
              className="intro-image"
              image={getImage(careersData.titleImage)}
              alt={careersData.titleImage.title}
            />
          </Col>
          <Col className="col-md-6 col-12 d-inline-block" />
          <Col className="col-md-6 col-12 d-inline-block careers-intro-text careers-text">
            {careersData.descriptionRight.descriptionRight}
          </Col>
        </Container>
      </div>
      <div className="careers-main">
        <Container>
          {/*<Col className="col-lg-6 col-12 d-inline-block careers-main-left">*/}
          {careersItemsData && <Item careersItemsData={careersItemsData} />}
          {/*</Col>*/}
          <Col className="col-xl-6 col-12 d-inline-block careers-main-right">
            <div className="careers-main-image">
              <GatsbyImage
                className="main-image"
                image={getImage(careersData.imageRight)}
                alt={careersData.imageRight.title}
              />
            </div>
          </Col>
        </Container>
      </div>
    </div>
  );
};

Careers.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default Careers;
