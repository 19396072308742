import * as React from "react";
import { Col, Container } from "react-bootstrap";
import "./WorkWithUs.scss";
import CallToAction from "../../CallToAction/CallToAction";
import query from "./query";
import Item from "./Item/Item";
import {
  filterDataByLanguage,
  filterListDataByLanguage,
} from "../../../Utils/Language";
import PropTypes from "prop-types";

const WorkWithUs = (props) => {
  const data = query();
  const workWithUsData = filterDataByLanguage(
    props.lang,
    data.allContentfulWorkWithUs
  );
  const workWithUsItemsData = filterListDataByLanguage(
    props.lang,
    data.allContentfulWorkWithUsItem
  );

  return (
    <div className="work-with-us-content">
      <Container>
        <Col className="col-md-6 col-12">
          <div className="work-with-us-title careers-title">
            {workWithUsData.title}
          </div>
          <div className="work-with-us-text-top careers-text">
            {workWithUsData.textLeft.textLeft}
          </div>
        </Col>
        <div className="work-with-us-list">
          {workWithUsItemsData && (
            <Item workWithUsItemsData={workWithUsItemsData} />
          )}
        </div>
        <Col className="col-md-6 col-12 d-inline-block" />
        <Col className="col-md-6 col-12 d-inline-block work-with-us-text-bottom careers-text">
          <div>{workWithUsData.textRight.textRight}</div>
          <div className="work-with-us-link-bottom">
            <CallToAction
              className="work-with-us-cta-bottom careers-title"
              to={workWithUsData.link}
              label={workWithUsData.linkName}
              mailTo={true}
            />
          </div>
        </Col>
      </Container>
    </div>
  );
};

WorkWithUs.propTypes = {
  lang: PropTypes.string.isRequired,
};

export default WorkWithUs;
