import * as React from "react";
import "./Item.scss";
import { Col } from "react-bootstrap";
import PropTypes from "prop-types";

const Item = (props) => {
  return (
    // <div key="careers-items" className="careers-items">
    <div
      key="careers-items"
      className="col-xl-6 col-12 d-inline-block careers-items"
    >
      {/*<Col className="col-md-6 col-12 d-inline-block careers-main-left">*/}
      <Col className="careers-main-left">
        {props.careersItemsData.map((el) => (
          <div key={el.id} className="careers-item">
            <div className="careers-item-title careers-title">
              {el.itemName}
            </div>
            <div className="careers-item-text careers-text">
              {el.itemDescription.itemDescription}
            </div>
          </div>
        ))}
      </Col>
    </div>
  );
};

Item.propTypes = {
  careersItemsData: PropTypes.object.isRequired,
};

export default Item;
